import React, { useState, useEffect, useRef } from 'react';
import Confetti from 'react-confetti';
import styles from './style.module.scss';
import { Breadcrumb, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import { api } from '../../services/apiClient';
import Filtro from '../../Components/Filtro';
import { useAuth } from '../../contexts/AuthContext';
import CheckinsRestauranteTable from '../../Components/CheckinsRestauranteTable';

const CheckinsRestaurante = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const previousDataRef = useRef([]);
  const audioRef = useRef(null);

  useEffect(() => {
    // Inicializa o elemento de áudio
    audioRef.current = new Audio('/sounds/gemidane.mp3'); // Caminho absoluto a partir de public

    // Tenta reproduzir o áudio uma vez para desbloquear a reprodução automática
    audioRef.current
      .play()
      .then(() => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      })
      .catch((error) => {
        console.error('Erro ao tentar reproduzir o som inicialmente:', error);
        // Mesmo que falhe, continua sem interrupções
      });

    // Chama a função inicialmente
    fetchDataCheckins();

    // Configura o intervalo para chamar a função a cada X milissegundos
    const interval = setInterval(() => {
      fetchDataCheckins();
    }, 5000); // Atualiza a cada 5 segundos

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(interval);
  }, [user.restaurante.id]); // Adicionado como dependência

  // Função para lidar com a impressão de um pedido específico
  const handlePrint = (order) => {
    try {
      console.log('\n\n\norder', order);
      console.log('TESTE NOVOOO');

      console.log("order.codigo.split('-')[0]", order.codigo.split('-')[0]);
      window.electronAPI.printOrder(order);
      message.success(`Pedido ${order.codigo.split('-')[0]} enviado para impressão.`);
    } catch (e) {
      console.log('e', e);
      console.log('e', e.message);
      message.error(`Pedido não foi impresso.`);
    }
    // Envie os dados do pedido para o Electron via IPC
  };

  const fetchDataCheckins = async () => {
    // setLoading(true);
    try {
      const response = await api.get(`/restaurante/checkin/${user.restaurante.id}`);
      const newData = response.data.message;

      // Verifica se há novos pedidos comparando com os dados anteriores
      const previousData = previousDataRef.current;
      const newOrders = newData.filter((newOrder) => !previousData.some((order) => order.id === newOrder.id));

      if (newOrders.length > 0 && previousData.length > 0) {
        // Reproduz o som de notificação
        playNotificationSound();

        // Imprime cada novo pedido
        newOrders.forEach((order) => {
          console.log('order', order);
          handlePrint(order);
        });

        // Ativa os confetes
        triggerConfetti();
      }

      setData(newData);
      previousDataRef.current = newData; // Atualiza os dados anteriores
      console.log('response.data.message', newData);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      message.error('Erro ao buscar os checkins!');
    }
    setLoading(false);
  };

  const playNotificationSound = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0; // Reinicia o áudio para garantir que ele toque novamente
      audioRef.current.play().catch((error) => {
        console.error('Erro ao reproduzir o som:', error);
      });
    }
  };

  const triggerConfetti = () => {
    setShowConfetti(true);
    // Desativa os confetes após 3 segundos
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  };

  const filtroOptions = [
    { value: 'aguardando', label: 'Aguardando Confirmação' },
    { value: 'finalizado', label: 'Finalizado' },
  ];

  const handleFilter = (filters) => {
    console.log('Filtrando com:', filters);
    // Lógica de filtragem dos dados da tabela
  };

  const handleConfirmCheckin = async (checkin) => {
    console.log('checkin', checkin);
    try {
      const response = await api.put(`/restaurante/checkin/status/${checkin}`, {
        status: 'preparando',
      });
      console.log('checkin aceito', checkin);
      console.log('response', response);
      message.success('Checkin aceito com sucesso!');
      // Atualiza os dados após a confirmação
      setData((prevData) => prevData.map((item) => (item.id === checkin ? { ...item, status: 'preparando' } : item)));
      // Atualiza os dados anteriores
      previousDataRef.current = data.map((item) => (item.id === checkin ? { ...item, status: 'preparando' } : item));
    } catch (error) {
      console.error('Erro ao atualizar checkin:', error);
      message.error(`Erro ao atualizar checkin: ${error.message}`);
    }
  };

  return (
    <div className={styles.container}>
      {/* Condicional para exibir os confetes */}
      {showConfetti && <Confetti />}

      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: 'Checkins',
          },
        ]}
      />
      <Button
        onClick={() => {
          // Objeto mockado para teste
          const mockOrder = {
            id: 'teste-123',
            codigo: 'pedido-1234',
            status: 'pendente',
            createdAt: new Date().toISOString(),
            // ...coloque aqui as chaves que você espera, ex:
            valor: 25.5,
            pedidosItens: [
              {
                produto: { titulo: 'Hambúrguer' },
                quantidade: 2,
                valor: 15.0,
                adicional_pedido_item: [{ adicionais: { titulo: 'Queijo Extra', valor: 2 } }],
              },
            ],
          };

          // Chama a função de impressão com dados falsos
          handlePrint(mockOrder);
        }}
      >
        Testar Impressão
      </Button>

      <Filtro options={filtroOptions} onFilter={handleFilter} />

      {/* Elemento de áudio no JSX */}
      <audio ref={audioRef} src='/sounds/gemidane.mp3' preload='auto' />

      <CheckinsRestauranteTable data={data} loading={loading} handleConfirmCheckin={handleConfirmCheckin} />
    </div>
  );
};

export default CheckinsRestaurante;
